// core version + navigation, pagination modules:
import Swiper, {Autoplay, EffectFade, Controller, Pagination,} from 'swiper';

// configure Swiper to use modules
Swiper.use([Pagination, Autoplay, EffectFade, Controller]);

const swiperAct = new Swiper('#swiper-intro-visuel .swiper', {
    effect: "fade",
    speed: 2000,
    loop: true,
    autoplay: {
        delay: 6500,
    },
    // Navigation arrows
    pagination: {
        el: ".swiper-pagination",
        clickable: true
    },
});
let swiperActText = new Swiper('#swiper-intro-text .swiper', {
    loop: true,
    speed: 2000,
    controller: {
        control: swiperAct
    },

});
swiperAct.controller.control = swiperActText;


//Swiper Home
new Swiper('#swiper-marque', {
    slidesPerView: 8,
    //loopedSlides: 10,
    spaceBetween: 8,
    loop: true,
    speed: 1600,
    autoplay: {
        delay: 0,
    },
    breakpoints: {
        320: {
            slidesPerView: 2,
        },
        480: {
            slidesPerView: 3,
        },
        640: {
            slidesPerView: 4,
        },
        760: {
            slidesPerView: 5,
        },
        860: {
            slidesPerView: 6,
            spaceBetween: 10,
        },
        1020: {
            slidesPerView: 7,
            spaceBetween: 20,
        },
        1150: {
            slidesPerView: 8,
            spaceBetween: 30,
        }
    }
});


document.querySelectorAll("header nav li a").forEach((item) => {
    item.addEventListener("click", (e) => {
        e.preventDefault();
        document.body.classList.toggle('menu-open');
        window.scrollTo({
            top: document.querySelector(item.dataset.goto).offsetTop - 50,
            behavior: 'smooth',
        })
    })
})
document.querySelectorAll(".btn-hover").forEach((item) => {
    item.addEventListener("click", (e) => {
        e.preventDefault();
        document.body.classList.remove('menu-open');
        window.scrollTo({
            top: document.querySelector('#contact').offsetTop - 50,
            behavior: 'smooth',
        })
    })
})
document.querySelectorAll("#prestations .content article").forEach((item) => {
    item.addEventListener("click", (e) => {
        e.preventDefault();
        item.classList.toggle('open');
    })
})


